import React from "react";
import resumeFile from "../documents/Joshua_Cyrenian_Resume.pdf";

const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Get to Know Me
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Joshua Cyenian,</span> a Software
              Developer
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
            I'm an experienced software developer and leader of 10+ years with
            10 years of frontend development (ReactJS, JavaScript, Redux and TypeScript), 
            and 6 years of full-stack (.NET, NodeJS, Python, Java, Guidewire) and mobile development (iOS, Android, React Native). 
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
            I do what it takes to successfully complete your projects,
            work and learn quickly, and always adapt the project to meet your goals. 
            If you or your company are positive and collaborative, and thrive in a team environment,
            don't hesitate to contact me!
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Joshua Cyenian
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:joshua@cyenian.me">joshua@cyenian.me</a>
                </li>
                <li>
                  <span className="fw-600 me-2">Phone:</span>
                  <a href="tel:1.604.442.4464">+1 (604) 442-4464</a>
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Vancouver,
                  Canada
                </li>
              </ul>
              <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                Download CV
              </a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>10</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experiance
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>100</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
